import React from "react";
import RequestButton from "../RequestButton";

const PrimaryCareInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">
          Primary Care Q & A
        </p>
        <p className="text-xl text-gray-800 mb-2">What is primary care?</p>
        <p className="text-md text-gray-600 mb-12">
          The medical team at Sunstate Medical Associates specializes in
          internal medicine, with three physicians on staff. They prioritize
          close relationships with patients to help them achieve and maintain
          optimal health through education, the latest health-related
          information, and up-to-date care.
        </p>
        <p className="text-xl text-gray-800 mb-2">
          What is primary care used for?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Primary care at Sunstate Medical Associates involves disease
          prevention, diagnosis, and treatment of illnesses and chronic
          diseases, including (but not limited to):
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Colds and flu</li>
          <li>Diabetes</li>
          <li>High blood pressure and high cholesterol</li>
          <li>Chronic lung disease</li>
          <li>Heart problems</li>
          <li>Thyroid and other hormone problems</li>
          <li>Obesity</li>
          <li>Arthritis</li>
          <li>Asthma and allergies</li>
          <li>Depression or anxiety</li>
          <li>Skin disorders</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          If you have questions or concerns about a specific medical condition,
          the Sunstate Medical Associates team discusses them with you and gives
          you a voice in your care.
        </p>
        <p className="text-xl text-gray-800 mb-2">
          What happens during primary care appointments?
        </p>
        <p className="text-md text-gray-600 mb-4">
          During primary care appointments at Sunstate Medical Associates, you
          can expect one or more of the following:
        </p>
        <p className="text-lg text-gray-700 mb-2">Wellness exams</p>
        <p className="text-md text-gray-600 mb-2">
          The Sunstate Medical Associates team offers wellness exams. They
          review your personal and family medical history, check your vital
          signs, and complete a comprehensive physical exam to detect or rule
          out illnesses and diseases.
        </p>
        <p className="text-md text-gray-600 mb-3">
          Your doctor offers prostate cancer screening in men and pelvic exams,
          including Pap tests when necessary, in women to screen for cervical
          cancer. They might use blood or urine tests to diagnose specific
          medical problems.
        </p>
        <p className="text-lg text-gray-700 mb-2">Chronic disease management</p>
        <p className="text-md text-gray-600 mb-3">
          During chronic disease management appointments, your primary care
          doctor follows up with you to determine how well you’re managing a
          disease. They detect or rule out complications and make medication
          dosage changes when necessary.
        </p>
        <p className="text-lg text-gray-700 mb-2">Sick visits</p>
        <p className="text-md text-gray-600 mb-12">
          If you experience symptoms or an illness, such as a cold, strep
          throat, flu, a sinus or ear infection, or a urinary tract infection,
          your primary care specialist can diagnose your condition and offer
          treatment for symptom relief.
        </p>
        <p className="text-xl text-gray-800 mb-2">
          Which primary care treatments are available?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Your Sunstate Medical Associates team personalizes each primary care
          treatment based on your health, lifestyle, and preferences. They might
          recommend:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Weight loss, diet, and exercise changes</li>
          <li>Dietary supplements</li>
          <li>Over-the-counter or prescription medications</li>
          <li>Injections</li>
          <li>Birth control</li>
          <li>Hormone replacement therapy</li>
          <li>Additional diagnostic testing</li>
          <li>Skin treatments</li>
          <li>Breathing treatments</li>
          <li>Wound and fracture care</li>
        </ul>
        <p className="text-md text-gray-600 mb-3">
          If you have a severe or complex medical problem, your primary care
          doctor refers out to specialists in your area.
        </p>
        <p className="text-md text-gray-600 mb-10">
          If you’d like to maintain optimal health or get treated for an injury,
          disease, or illness, schedule an appointment with Sunstate Medical
          Associates by phone or online today.
        </p>
        <RequestButton />
      </div>
    </div>
  );
};

export default PrimaryCareInfo;
