import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import ServicesHeader from "../../components/ServicesHeader";
import PrimaryCareInfo from "../../components/services/PrimaryCareInfo";

const PrimaryCare = () => {
  return (
    <Layout>
      <PageHeader text="Primary Care" />
      <ServicesHeader>
        Finding a primary care team you can trust gives you the best chance of
        staying healthy. At Sunstate Medical Associates in Lake Mary, Florida,
        the expert internal medicine team diagnoses, treats, and helps you
        manage diseases to optimize your quality of life. To schedule a wellness
        exam or if you experience unusual symptoms, book an appointment with
        Sunstate Medical Associates by phone or online today.
      </ServicesHeader>
      <PrimaryCareInfo />
      <Conditions />
    </Layout>
  );
};

export default PrimaryCare;
